
import { Component, Vue } from "vue-property-decorator";

@Component
export default class DocumentDownload extends Vue {
  mounted() {
    if (this.$route.params.file) {
      console.log("File here ", this.$route.params.file);

      // if (this.$route.params.file.split(".pdf"))) {

      // }
    }
  }
}
