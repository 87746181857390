
import { Component, Vue } from "vue-property-decorator";
import Nav from "@/store/modules/Nav";

@Component
export default class Footer extends Vue {
  get sidebar() {
    return Nav.getSidebar;
  }
  set sidebar(val: boolean) {
    Nav.setSideBar(val);
  }
  get menuItems() {
    return Nav.getMenuItems;
  }
  socialItems = [
    {
      title: "Facebook",
      path: "https://www.facebook.com/FirearmCompliance/",
      icon: "mdi-facebook",
    },
  ];
  footerItems = [
    {
      title: "Privacy Policy",
      path: "#privacy",
      icon: "mdi-account",
    },
    {
      title: "Terms",
      path: "#terms",
      icon: "mdi-account",
    },
  ];
}
