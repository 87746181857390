import axios from "axios";
import Auth from "@/store/modules/Auth";
import { NotificationLib, Storage } from "@/helpers";

const axiosNoAuth = axios.create({
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  baseURL: process.env.VUE_APP_API_BASE,
});
//only after signed in
const axiosNoErrorInstance = axios.create({
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  baseURL: process.env.VUE_APP_API_BASE,
});
const axiosInstance = axios.create({
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  baseURL: process.env.VUE_APP_API_BASE,
});
const generateMessageFromError = (error: unknown): string => {
  let message = "";
  if (axios.isAxiosError(error)) {
    console.log("To json response ", error.toJSON(), error);
    if (error.response) {
      if (error.response.data && error.response.data != "") {
        // for our BE
        if (
          (error.response.data as any).status &&
          (error.response.data as any).title
        ) {
          message = (error.response.data as any).status + ": ";
          message += (error.response.data as any).title + `<br />`;
          const errs = (error.response.data as any).errors;
          if (errs) {
            Object.entries(errs).forEach((e) => {
              message += e[1] + `<br />`;
            });
          }
        } else if (
          (error.response.data as any).status &&
          (error.response.data as any).message
        ) {
          message = (error.response.data as any).status + ": ";
          message += (error.response.data as any).message;
        } else {
          message = JSON.stringify(error.response.data);
        }
      } else if (error.response.status && error.response.statusText) {
        // general error message
        console.log("data if else ", error.response);
        message = error.response.status + ": ";
        message += error.response.statusText;
      } else {
        // root error
        console.log("data else ", error.response);
        if (error.code) {
          message = error.code + ": ";
        } else {
          message = "Unknown: ";
        }
        message += error.message;
      }
    } else {
      if (error.code) message = error.code + ": ";
      message += error.message;
    }
  } else {
    if (error instanceof Error) {
      if (error.message) {
        message = error.message;
      } else {
        console.error("error non message", error);
      }
    } else if (typeof error === "object") {
      const temp = JSON.stringify(error);
      if (temp) {
        message = temp;
      } else {
        console.error("Couldnt stringify error", error);
        // message = error;
      }
    } else {
      message = "Unidentified error: " + error;
    }
  }
  if (message == "") {
    return "Uknown issue: Contact Support.";
  }
  return message;
};

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const message = generateMessageFromError(error);
    console.log("Testing message :", message);
    NotificationLib.createErrorNotification(message);
    if (axios.isAxiosError(error)) {
      console.log("Axios inter: ", error);
      if (error.response && error.response?.status == 401) {
        Auth.setUserDetails(null);
        Storage.removeLogin();
      }
    }
    return Promise.reject(error);
  }
);
axiosNoErrorInstance.interceptors.request.use((restConfig) => {
  const u = Auth.isLoggedIn;
  if (u == true && Auth.getLoggedIn) {
    // is logged in
    const token = Auth.getLoggedIn.accessToken;
    // console.log("ACces token :", token, u, Auth);
    const config = restConfig;
    if (config.headers) {
      config.headers.Authorization = "Bearer " + Auth.getLoggedIn.accessToken;
    }

    return config;
  }
  return restConfig;
});
axiosInstance.interceptors.request.use((restConfig) => {
  const u = Auth.isLoggedIn;
  if (u == true && Auth.getLoggedIn) {
    // is logged in
    const token = Auth.getLoggedIn.accessToken;
    // console.log("ACces token :", token, u, Auth);
    const config = restConfig;
    if (config.headers) {
      config.headers.Authorization = "Bearer " + Auth.getLoggedIn.accessToken;
    }

    return config;
  }
  return restConfig;
});

export {
  axiosInstance,
  axiosNoAuth,
  axiosNoErrorInstance,
  generateMessageFromError,
};
