
import { Component, Vue } from "vue-property-decorator";

@Component
export default class Tutorials extends Vue {
  tutorials = [
    {
      link: "https://www.youtube.com/embed/dQw4w9WgXcQ",
      name: "Learn Something 1",
    },
    {
      link: "https://www.youtube.com/embed/dQw4w9WgXcQ",
      name: "Learn Something 2",
    },
    {
      link: "https://www.youtube.com/embed/dQw4w9WgXcQ",
      name: "Learn Something 3",
    },
    {
      link: "https://www.youtube.com/embed/dQw4w9WgXcQ",
      name: "Learn Something 4",
    },
    {
      link: "https://www.youtube.com/embed/dQw4w9WgXcQ",
      name: "Learn Something 5",
    },
    {
      link: "https://www.youtube.com/embed/dQw4w9WgXcQ",
      name: "Learn Something 6",
    },
    {
      link: "https://www.youtube.com/embed/dQw4w9WgXcQ",
      name: "Learn Something 7",
    },
    {
      link: "https://www.youtube.com/embed/dQw4w9WgXcQ",
      name: "Learn Something 8",
    },
    {
      link: "https://www.youtube.com/embed/dQw4w9WgXcQ",
      name: "Learn Something 9",
    },
    {
      link: "https://www.youtube.com/embed/dQw4w9WgXcQ",
      name: "Learn Something 10",
    },
    {
      link: "https://www.youtube.com/embed/dQw4w9WgXcQ",
      name: "Learn Something 11",
    },
    {
      link: "https://www.youtube.com/embed/dQw4w9WgXcQ",
      name: "Learn Something 12",
    },
    {
      link: "https://www.youtube.com/embed/dQw4w9WgXcQ",
      name: "Learn Something 13",
    },
    {
      link: "https://www.youtube.com/embed/dQw4w9WgXcQ",
      name: "Learn Something 14",
    },
    {
      link: "https://www.youtube.com/embed/dQw4w9WgXcQ",
      name: "Learn Something 15",
    },
  ];
}
