import {
  axiosInstance,
  axiosNoErrorInstance,
  generateMessageFromError,
} from "@/plugins/axios";
import Auth from "@/store/modules/Auth";
import SubscriptionModule from "@/store/modules/Subscription";
import axios from "axios";
import { subscription } from "subscriptionModels";

const Subscription = () => {
  const addSubscriber = async (id: number) => {
    const res = await axiosInstance.get("/api/subscriptions/addSubscriber", {
      params: {
        subscriptionID: id,
      },
    });
    console.log("Res for subscribe: ", res);
    const redirect = (res.data.message as string).split("Added Subscriber,")[1];
    window.location.replace(redirect);
    return Promise.resolve();
  };
  const removeSubscriber = async () => {
    const res = await axiosInstance.get(
      "/api/subscriptions/cancelSubscription"
    );
    console.log("Res for unsubscribe: ", res);
    return Promise.resolve();
  };
  const getSubscription = async () => {
    if (Auth.isLoggedIn) {
      const res = await axiosNoErrorInstance
        .get("/api/subscriptions/getUserSubscription")
        .catch((err) => {
          const msg = generateMessageFromError(err);
          if (axios.isAxiosError(err)) {
            if (err.response && err.response.status == 404) {
              return Promise.resolve(null);
            }
          }
          return Promise.reject(err);
        });
      if (res != null && res.status == 200) {
        SubscriptionModule.setSubscription(res.data as subscription);
      } else {
        SubscriptionModule.setSubscription(null);
      }
      console.log("Res for getSub: ", res);
      return Promise.resolve(res ? res.data : null);
    }
  };
  const updateSubscription = async (id: number, additionalUsers?: number) => {
    const res = await axiosInstance.post(
      "/api/subscriptions/updateUserSubscription",
      {
        subscriptionID: id,
        additionalUsers: additionalUsers,
      }
    );
    console.log("Res for getSub: ", res);
    return Promise.resolve(res.data);
  };

  const loadPrices = async () => {
    const res = await axiosInstance.get(
      "/api/PayFastSubscription/getAllSubscriptions"
    );
    console.log("Load prices ", res.data);
    SubscriptionModule.setPackages(res.data as subscription[]);
    return;
  };

  return {
    loadPrices,
    updateSubscription,
    addSubscriber,
    removeSubscriber,
    getSubscription,
  };
};
export default Subscription;
