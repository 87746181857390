
import { Component, Vue } from "vue-property-decorator";
import Footer from "@/components/Footer.vue";

@Component({
  components: {
    Footer,
  },
})
export default class PrivacyPolicyView extends Vue {}
