
import { Component, Vue } from "vue-property-decorator";
import Nav from "@/store/modules/Nav";
import { axiosInstance } from "@/plugins/axios";
import { UserDetailsResponse, userRole } from "models";
import { subscription } from "subscriptionModels";
import AuthModule from "@/store/modules/Auth";
import SubscriptionModule from "@/store/modules/Subscription";
import PriceList from "@/components/PriceList.vue";
import { Subscription } from "@/helpers";
import axios, { AxiosError } from "axios";

@Component({
  components: {
    PriceList,
  },
})
export default class Prices extends Vue {}
