
import { Component, Vue } from "vue-property-decorator";
import Nav from "@/store/modules/Nav";

@Component
export default class FooterBanner extends Vue {
  subject = "";
  email = "";
  message = "";
  name = "";

  get getLink() {
    return `mailto: info@zeroeight.co.za?subject=Feedback: ${this.subject}&body=${this.message}%0A%0A${this.name}`;
  }
}
