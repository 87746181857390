
import { Component, Vue } from "vue-property-decorator";
import AuthModule from "@/store/modules/Auth";
import Auth from "@/helpers/Authenticate";
import axios, { AxiosError } from "axios";
const authHook = Auth();

@Component
export default class Login extends Vue {
  loading = false;
  username = "";
  password = "";
  valid = false;
  reveal = false;
  error = {
    open: false,
    message: "",
  };
  rules = {
    required: (value: string): boolean | string =>
      !!value || "This field is Required.",
    uppercase: (value: string): boolean | string => {
      const pattern = /^(?=.*[A-Z])/;
      return pattern.test(value) || "Requires atleast 1 uppercase letter";
    },
    lowercase: (value: string): boolean | string => {
      const pattern = /^(?=.*[a-z])/;
      return pattern.test(value) || "Requires atleast 1 lowercase letter";
    },
    digit: (value: string): boolean | string => {
      const pattern = /^(?=.*\d)/;
      return pattern.test(value) || "Requires atleast 1 digit";
    },
    special: (value: string): boolean | string => {
      const pattern = /^(?=.*\W)/;
      return pattern.test(value) || "Requires atleast 1 special character";
    },
    /*  matchingPasswords: (value: string): boolean | string =>
      this.checkPasswordsMatch(value) || "Passwords do not match", */
  };
  get canLogin() {
    return this.valid;
  }
  async login(): Promise<void> {
    try {
      this.loading = true;
      this.error.open = false;
      if (this.username == null || this.password == null)
        return Promise.reject(
          "Both the USERNAME and their PASSWORD are required"
        );
      await authHook.loginFull(this.username, this.password);
      if (this.$route.query && this.$route.query.redirectedFrom) {
        // redirect back to where they came from
        await this.$router
          .push({
            path: this.$route.query.redirectedFrom as string,
          })
          .catch((err) => {
            console.log("Error back to where they came from", err);
          });
      } else {
        await this.$router
          .push({
            name: "home",
            hash: "#pricing",
          })
          .catch((err) => {
            console.log("Error navigating to dash...", err);
          });
      }
    } catch (err) {
      console.error("Cant log in: ", err);
      if (axios.isAxiosError(err)) {
        const temp = err as AxiosError;
        if (temp.response && temp.response.data) {
          if ((temp.response.data as any).status) {
            this.error.message = "";
            this.error.message =
              (temp.response.data as any).status +
              ": " +
              (temp.response.data as any).title;
            this.error.open = true;
            return;
          }

          this.error.message =
            temp.response.status + ": " + temp.response.statusText;
          this.error.open = true;
        } else {
          this.error.message = temp.code + ": " + temp.message;
          this.error.open = true;
        }
      }
    } finally {
      this.loading = false;
    }
  }
}
