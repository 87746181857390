
import { Component, Vue } from "vue-property-decorator";
import Nav from "@/store/modules/Nav";
import { axiosInstance } from "@/plugins/axios";
import { UserDetailsResponse, userRole } from "models";
import { subscription } from "subscriptionModels";
import AuthModule from "@/store/modules/Auth";
import SubscriptionModule from "@/store/modules/Subscription";
import { Subscription } from "@/helpers";
import axios, { AxiosError } from "axios";

@Component
export default class Prices extends Vue {
  error = {
    open: false,
    message: "",
  };
  changeTo: {
    open: boolean;
    item: null | subscription;
  } = {
    open: false,
    item: null,
  };
  get getSubscription() {
    return SubscriptionModule.getSubscription;
  }
  get getPackages() {
    return SubscriptionModule.getPackages;
  }
  loading = false;
  // packages: subscription[] = [];
  promptPayfast: {
    open: boolean;
    item: subscription | null;
  } = {
    open: false,
    item: null,
  };
  get getLoggedIn() {
    return AuthModule.isLoggedIn;
  }
  async updatePackage(id: number) {
    const res = await Subscription.updateSubscription(id);
    console.log("Change package ", res);
  }
  async changePackage(id: number) {
    try {
      this.loading = true;
      await this.updatePackage(id);
      await Subscription.getSubscription();
      await this.loadPrices();
      this.changeTo.open = false;
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }
  async redirectToPayfast() {
    try {
      if (this.promptPayfast.item == null) return;
      const temp = await this.signUp(this.promptPayfast.item.id);
      console.log("Redirect response");
    } catch (err) {
      console.log("Cant subscribe: ", err);
    }
  }
  async signUp(id: number) {
    try {
      this.loading = true;
      if (AuthModule.isLoggedIn) {
        console.log("Signup?", AuthModule.getUserRoles.length);
        if (AuthModule.getUserRoles.length > 0) {
          if (AuthModule.getUserRoles.some((e) => e.roleId == 2)) {
            // alert("Can signup");
            console.log("item: ", id);
            await Subscription.addSubscriber(id);
            // window.open(redirect);
          } else {
            alert("Your account doesnt have sufficient permissions");
          }
          // AuthModule.getLoggedInD
        }
      } else {
        alert("Push to login/register");
      }
    } catch (err) {
      console.log("Error signing up ", err);
    } finally {
      this.loading = false;
    }
  }
  async mounted() {
    try {
      await this.loadPrices();
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const temp = err as AxiosError;
        if (temp.response && temp.response.data) {
          if ((temp.response.data as any).status) {
            this.error.message = "";
            this.error.message =
              (temp.response.data as any).status +
              ": " +
              (temp.response.data as any).title;
            this.error.open = true;
            return;
          }

          this.error.message =
            temp.response.status + ": " + temp.response.statusText;
          this.error.open = true;
        } else {
          this.error.message = temp.code + ": " + temp.message;
          this.error.open = true;
        }
      }
    }
  }
  async loadPrices() {
    await Subscription.loadPrices();
  }
}
