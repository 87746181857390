
import { Component, Vue } from "vue-property-decorator";
import Nav from "@/store/modules/Nav";

@Component
export default class AboutUs extends Vue {
  slideshow = 0;
  images: any = [];

  mounted() {
    this.importAll(require.context("@/assets/screenshots/", false));
  }
  importAll(r: any) {
    r.keys().forEach((key: any) =>
      this.images.push({ pathLong: r(key), pathShort: key })
    );
  }
}
