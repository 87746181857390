
import { Component, Vue } from "vue-property-decorator";

@Component
export default class Testimonials extends Vue {
  testimonialsError(err: any) {
    console.error("Testimonials failed: ", err);
  }
  testLabel() {
    console.log("Testing label click");
  }
  testimonials = [
    {
      title: "Sweiss Harms",
      subtitle: "CPG SECURITY - OWNER",
      img: require("@/assets/testimonials/cpg.jpg"),
      message:
        "I am operationally active in my business. Zero Eight gives me the compliance information I need to make decisions on the go",
    },
    {
      title: "Roger Ladeira",
      subtitle: "REAPER SECURITY – OWNER",
      img: require("@/assets/testimonials/reaper.png"),
      message:
        "When I heard it was software, I thought it was going to cost an arm and a leg. Zero Eight is surprisingly affordable for what it does and Carl goes out of his way to service his clients",
    },
    {
      title: "Milton",
      subtitle: "STALLION SECURITY – CONTROL ROOM MANAGER",
      img: require("@/assets/testimonials/Stallion.jpg"),
      message:
        "This system is easy to use and I have all the information I need online. No more excel spreadsheets. The online permits have saved us a lot in travelling time and expense",
    },
    {
      title: "Jonathan Maier",
      subtitle: "PHP SECURITY – OPERATIONS MANAGER",
      img: require("@/assets/testimonials/PHPSecurity.png"),
      message:
        "Zero Eight gives me access to compliance information wherever I am. I no longer need to wait for the information I need’’ The online firearm permits have saved us a lot of time, especially when it comes to auditing",
    },
    {
      title: "Ashley Scheun",
      subtitle: "MAMBA SECURITY – OPERATIONS MANAGER",
      img: require("@/assets/testimonials/Mamba.png"),
      message:
        "Great service from Carl and his team. The software is awesome! It has made my life less complicated when it comes to compliance And is very user-friendly",
    },
  ];
}
