
import { Component, Vue } from "vue-property-decorator";
import Nav from "@/store/modules/Nav";
import { Subscription } from "@/helpers";
import SubscriptionModule from "@/store/modules/Subscription";
import PriceList from "@/components/PriceList.vue";

@Component({
  components: {
    PriceList,
  },
})
export default class ManageSubscription extends Vue {
  loading = true;
  changeSubscriptionToggle = false;
  cancelSubscriptionToggle = false;

  additionalUsers = 0;

  get getSubscription() {
    return SubscriptionModule.getSubscription;
  }
  async mounted() {
    try {
      this.loading = true;
      await Subscription.getSubscription();
    } catch (err) {
      console.log("Cannot init: ", err);
    } finally {
      this.loading = false;
    }
  }
  async cancelSubscription() {
    try {
      this.loading = true;
      await Subscription.removeSubscriber();
      await Subscription.getSubscription();
    } catch (err) {
      console.log("Error cancelling subscription: ", err);
    } finally {
      this.loading = false;
    }
  }
  async updateSubscription() {
    try {
      this.loading = true;
      if (SubscriptionModule.getSubscription == null) throw "No Subscription";
      await Subscription.updateSubscription(
        SubscriptionModule.getSubscription.id,
        this.additionalUsers
      );
      await Subscription.getSubscription();
    } catch (err) {
      console.log("Error cancelling subscription: ", err);
    } finally {
      this.loading = false;
    }
  }
}
