
import { Component, Vue } from "vue-property-decorator";
import Nav from "@/store/modules/Nav";

@Component
export default class Carousel extends Vue {
  tab = null;
  items = [
    {
      step: "Compliance with the Firearms",
      img: require("@/assets/slider2.png"),
      text: "Compliance with the Firearms Control Act 60 of 2000 can sometimes feel like a nightmare. Over and above this you need to rely on employees to manage this process on excel spreadsheets and a mountain of paper registers.",
    },
    {
      step: "Zero Eight Firearm Compliance",
      img: require("@/assets/slider3.png"),
      text: "Zero Eight Firearm Compliance are experienced in assisting security companies improve their security officer and firearm compliance, through online compliance visibility and a built-in process flow. Using Zero Eight helps security companies gain total control of firearm compliance, by speeding up and enhancing the management process.",
    },
  ];
}
