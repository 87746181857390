
import { Component, Vue } from "vue-property-decorator";
import Nav from "@/store/modules/Nav";

@Component
export default class Features extends Vue {
  features = [
    {
      icon: "mdi-menu",
      text: "Uncluttered & User-friendly Navigation",
    },
    {
      icon: "mdi-account-tie-voice",
      text: "Expert Instruction",
    },
    {
      icon: "mdi-briefcase-variant",
      text: "Regulation 21 Expiry Monitoring",
    },
    {
      icon: "mdi-card-account-details",
      text: "Drivers Licence Expiry Monitoring",
    },
    {
      icon: "mdi-office-building",
      text: "Business Purpose Proficiency",
    },
    {
      icon: "mdi-account",
      text: "SAPS Competency Expiry Monitoring",
    },
    {
      icon: "mdi-laptop",
      text: "SAPS Competency Validity Reports",
    },
    {
      icon: "mdi-cellphone",
      text: "Mobile App",
    },
    {
      icon: "mdi-card-account-details",
      text: "Drivers Licence Validity Reports",
    },
    {
      icon: "mdi-laptop-account",
      text: "PSIRA Validity Reports",
    },
    {
      icon: "mdi-shield-account",
      text: "Proficiency Certificate Reports",
    },
    {
      icon: "mdi-shield-half-full",
      text: "Regulation 21 Validity Reports",
    },
  ];
}
