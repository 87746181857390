
import { Component, Vue, Watch } from "vue-property-decorator";
import { fileToBase64 } from "@/helpers/File";
import Nav from "@/store/modules/Nav";
import { axiosInstance, axiosNoAuth } from "@/plugins/axios";
import Auth from "@/helpers/Authenticate";
import axios, { AxiosError } from "axios";
const authHook = Auth();

interface secFirm {
  ID: number;
  UserId: string;
  RegistrationNumber: string;
  CompanyName: string;
  ContactNumber: string;
  CreatedBy: string;
  UpdatedBy: string;
  UpdatedDate: string;
  user?: string;
  IsActive: boolean;
  CompanyAddress: string;
  ContactPerson: string;
  Surname: string;
  Name: string;
  ExpiryDate: string;
  AROLimit: number;
  SGLimit: number;
  CompanyLogo: string;
}
interface secFirmSubmit {
  surname: string;
  name: string;
  registrationNumber: string;
  companyName: string;
  contactNumber: string;
  companyAddress: string;
}
interface submitRegister {
  securityFirm: secFirmSubmit;
  userName: string;
  password: string;
  expiryDate: string;
  logo: File | null;
}
@Component
export default class Register extends Vue {
  // TODO: rules for field
  valid = false;
  expiryDate = false;
  activePicker: string | null = null;
  loading = false;
  confirmPassword = "";
  reveal = false;
  error = {
    open: false,
    message: "",
  };
  submit: submitRegister = {
    securityFirm: {
      registrationNumber: "",
      companyName: "",
      contactNumber: "",
      companyAddress: "",
      name: "",
      surname: "",
    },

    expiryDate: "",
    userName: "",
    password: "",
    logo: null,
  };
  goToHomepage() {
    this.$router.push({
      name: "home",
    });
  }
  get getMatchingPassword() {
    return (
      this.submit.password == this.confirmPassword || "Passwords do not match"
    );
  }
  rules = {
    passwordLength: (value: string): boolean | string =>
      value.length >= 8 || "Password should be 8 characters or longer",
    required: (value: string): boolean | string =>
      !!value || "This field is Required.",
    uppercase: (value: string): boolean | string => {
      const pattern = /^(?=.*[A-Z])/;
      return pattern.test(value) || "Requires atleast 1 uppercase letter";
    },
    lowercase: (value: string): boolean | string => {
      const pattern = /^(?=.*[a-z])/;
      return pattern.test(value) || "Requires atleast 1 lowercase letter";
    },
    digit: (value: string): boolean | string => {
      const pattern = /^(?=.*\d)/;
      return pattern.test(value) || "Requires atleast 1 digit";
    },
    special: (value: string): boolean | string => {
      const pattern = /^(?=.*\W)/;
      return pattern.test(value) || "Requires atleast 1 special character";
    },
    /*  matchingPasswords: (value: string): boolean | string =>
      this.checkPasswordsMatch(value) || "Passwords do not match", */
  };
  async addSecurityFirm() {
    try {
      this.loading = true;
      if (this.submit.logo) {
        const log = await fileToBase64(this.submit.logo);
        console.log("Test logo ", log);
        const res = await axiosNoAuth.post(
          "/api/securityfirmapi/addSecurityFirm",
          {
            securityFirm: this.submit.securityFirm,
            userName: this.submit.userName,
            password: this.submit.password,
            expiryDate: this.submit.expiryDate,
            logo: log,
          }
        );
        // TODO auto sign in after register.
        console.log("Register response", res.data);
        await authHook.loginFull(res.data.username, res.data.password);
        await this.$router
          .push({
            name: "home",
            hash: "#pricing",
          })
          .catch((err) => {
            console.log("Error navigating to dash...", err);
          });
      }
    } catch (err) {
      console.log("Error adding security firm: ", err);
      if (axios.isAxiosError(err)) {
        const temp = err as AxiosError;
        if (temp.response && temp.response.data) {
          if ((temp.response.data as any).status) {
            this.error.message = "";
            this.error.message =
              (temp.response.data as any).status +
              ": " +
              (temp.response.data as any).title;
            this.error.open = true;
            return;
          }

          this.error.message =
            temp.response.status + ": " + temp.response.statusText;
          this.error.open = true;
        } else {
          this.error.message = temp.code + ": " + temp.message;
          this.error.open = true;
        }
      }
    } finally {
      this.loading = false;
    }
  }
  @Watch("expiryDate")
  onExpiryChanged(val: boolean) {
    val && setTimeout(() => (this.activePicker = "YEAR"));
  }
  save(date: string) {
    if (this.$refs.expiryMenu) {
      (this.$refs.expiryMenu as any).save(date);
    }
  }
}
