
import { Component, Vue } from "vue-property-decorator";
import Nav from "@/store/modules/Nav";
import Auth from "@/store/modules/Auth";
import Subscription from "@/store/modules/Subscription";

@Component
export default class Appbar extends Vue {
  logout() {
    Auth.removeLoggedIn();
    Subscription.setSubscription(null);
  }
  get getAuth() {
    if (Auth.getLoggedIn) {
      return Auth.getLoggedIn.username;
    }
    return null;
  }
  get sidebar() {
    return Nav.getSidebar;
  }
  set sidebar(val: boolean) {
    Nav.setSideBar(val);
  }
  get menuItems() {
    return Nav.getMenuItems;
  }
}
