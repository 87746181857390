
import { Component, Vue } from "vue-property-decorator";
import Nav from "@/store/modules/Nav";

@Component
export default class FAQ extends Vue {
  faq = [
    {
      question: "What does the software do?",
      answer:
        "Zero Eight is an online solution that allows your Security Company to manage firearm compliance and eliminate files and spreadsheets.",
    },
    {
      question: "What are some of the features available?",
      answer:
        "Armed officer compliance register, firearm register, ammunition register, incidents, permit register, reports and alerts.",
    },
    {
      question: "Can I issue firearm permits?",
      answer:
        "Yes, there is no need to use manual permit books anymore. However, permits is a small part of what this solution offers.",
    },
    {
      question: "Are electronic permits lawful?",
      answer:
        "Yes! Electronic permits are totally legal and comply with the Firearms Control Act.",
    },
    {
      question: "Do I need to purchase the software",
      answer:
        "No, the software is offered at an affordable monthly license fee.",
    },
  ];
}
