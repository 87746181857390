
import { Component, Vue, Watch } from "vue-property-decorator";
import Appbar from "@/components/Appbar.vue";
import Navbar from "@/components/Navigation.vue";
import GlobalNotifications from "@/components/GlobalNotifications.vue";

@Component({
  components: {
    Appbar,
    Navbar,
    GlobalNotifications,
  },
})
export default class App extends Vue {
  // mounted() {
  //   if (Auth.isLoggedIn) {
  //   }
  // }
}
