import { axiosInstance, axiosNoAuth } from "@/plugins/axios";
import { UserDetailsResponse, userRole } from "models";
import AuthModule from "@/store/modules/Auth";

const Authenticate = () => {
  const loginFull = async (username: string, password: string) => {
    const res = await login({
      username: username.toLowerCase(),
      password: password,
    }).catch((err) => {
      return Promise.reject(err);
    });
    console.log("Set logged in from here: ", res);
    AuthModule.setUserDetails(res);
    const resRoles = await getRoles().catch((err) => {
      return Promise.reject(err);
    });
    console.log("Res roles: ", res);
    // const userRole = await getUser().catch((err) => {
    //   return Promise.reject(err);
    // });
    // AuthModule.setFirearmProxy(userRole.manageUser?.assignFirearmProxy);
    // AuthModule.setincidentProxy(userRole.manageUser?.incidentProxy);
    // AuthModule.setReportProxy(userRole.manageUser?.reportingProxy);
    AuthModule.setUserRoles(resRoles);
  };
  const login = async ({
    username,
    password,
  }: {
    username: string;
    password: string;
  }): Promise<UserDetailsResponse> => {
    try {
      const res = await axiosNoAuth.post(
        "/api/authenticate/loginweb",
        {
          username: username,
          password: password,
        }
        // {
        //   transformResponse: (r: {
        //     data: { expiration: string; token: string };
        //   }) => r.data,
        // }
      );
      // console.log("Logged in: ", res.data);
      const temp: UserDetailsResponse = {
        expiration: res.data.expiration,
        accessToken: res.data.token,
        username: username,
      };
      return temp;
    } catch (err) {
      return Promise.reject(err);
    }
  };

  const getRoles = async (): Promise<userRole[]> => {
    try {
      const res = await axiosInstance
        .get("/api/authenticate/GetUserRolesByID")
        .catch((err) => {
          if (err.response) {
            return Promise.reject(err.response);
          }
          return Promise.reject(err);
        });
      // console.log("Get Roles: ", res.data);
      return res.data;
    } catch (err) {
      return Promise.reject(err);
    }
  };

  const resetPassword = async (
    email: string | null = null /* ,
    password: string | null = null,
    confirmPassword: string | null = null */
  ): Promise<any> => {
    try {
      /* &password=${password}&confirmPassword=${confirmPassword} */
      const res = await axiosNoAuth
        .post(`/api/authenticate/ResetPasswordApp?email=${email}`)
        .catch((err) => {
          if (err.response) {
            return Promise.reject(err.response);
          }
          return Promise.reject(err);
        });
      // console.log("Get Roles: ", res.data);
      return res.data;
    } catch (err) {
      return Promise.reject(err);
    }
  };

  const getUser = async (): Promise<{
    manageUser?: {
      assignFirearmProxy: boolean;
      incidentProxy: boolean;
      reportingProxy: boolean;
    };
  }> => {
    try {
      const res = await axiosInstance
        .get("/api/authenticate/GetUser")
        .catch((err) => {
          if (err.response) {
            return Promise.reject(err.response);
          }
          return Promise.reject(err);
        });
      console.log("Get User: ", res.data);
      return res.data;
    } catch (err) {
      return Promise.reject(err);
    }
  };

  return {
    login,
    resetPassword,
    getRoles,
    getUser,
    loginFull,
  };
};
export default Authenticate;
