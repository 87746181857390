
import { Component, Vue } from "vue-property-decorator";
import Nav from "@/store/modules/Nav";
import Auth from "@/store/modules/Auth";

@Component
export default class Navigation extends Vue {
  get getAuth() {
    if (Auth.getLoggedIn) {
      return Auth.getLoggedIn.username;
    }
    return null;
  }
  get sidebar() {
    return Nav.getSidebar;
  }
  set sidebar(val: boolean) {
    Nav.setSideBar(val);
  }
  get menuItems() {
    return Nav.getMenuItems;
  }
  logout() {
    console.log("Test log out");
    Auth.removeLoggedIn();
  }
}
